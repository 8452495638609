import { IMenu } from "components/layout/Header";
import { Button } from "components/styled/Button";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { currentLangUrl } from "services/hooks/canonical";
import { DesktopMenuConteint } from "./styled";

function DesktopMenu({
  menu,
  activeCategories,
  closeCategories,
}: {
  menu: IMenu[];
  activeCategories: boolean;
  closeCategories: () => void;
}) {
  const navigate = useNavigate();
  const timerRef = useRef<any>(null);
  const [showBrands, setShowBrands] = useState<number | null>(null);

  const handleMouseEnter = (id: number) => {
    if (activeCategories) {
      timerRef.current = setTimeout(() => {
        closeCategories();
        setShowBrands(id);
      }, 700);
    } else {
      setShowBrands(id);
    }
  };

  const handleMouseLeave = () => {
    setShowBrands(null);
    clearTimeout(timerRef.current);
  };

  return (
    <DesktopMenuConteint>
      <div className="brands-wrapper">
        <div
          className="brands-container display-flex container"
          onMouseLeave={handleMouseLeave}
        >
          {menu.map((i) => (
            <div
              key={i.id}
              className={`brands-categories ${showBrands === i.id ? "hover" : ""}`}
              onMouseEnter={() => handleMouseEnter(i.id)}
            >
              {i.title}
            </div>
          ))}

          {!!showBrands &&
            !!menu.find((i) => i.id === showBrands)?.productBrands?.length && (
              <div className="brands container">
                {menu
                  .find((i) => i.id === showBrands)
                  ?.productBrands.map((item) => (
                    <Button
                      key={item.id}
                      size="xl"
                      variant="base-white"
                      onClick={() =>
                        navigate(
                          currentLangUrl() +
                            `/brand/${item.slug}?byCategorySlug=${menu.find((i) => i.id === showBrands)?.slug}`
                        )
                      }
                    >
                      {item.title}
                    </Button>
                  ))}
              </div>
            )}
        </div>
      </div>
    </DesktopMenuConteint>
  );
}

export default DesktopMenu;
